@import "colors.scss";

div.error-message {
  border: 1px solid #be0606;
  background-color: rgba(246, 97, 97, 0.9);
  color: #000;
  padding: 30px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  width: 500px;
  left: 50%;
  margin-left: -250px;
}


@media all and (max-width: 549px) {
    div.error-message {
        width: 100%;        
        left: 0;
        margin-left: 0;
    }
  }