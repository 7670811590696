.view-home {
  min-height: 600px;

  div.container
  {
      margin-bottom: 80px;
  }
  div.step {
    //display: flex;
    margin-bottom: 30px;

    div.step-number {
      //flex: 0 0 50px;
      font-weight: bold;
      font-size: 2rem;
      margin-bottom: 10px;
    }

    div.step-details {    

    }
  }
}