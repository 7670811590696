@import "colors.scss";

div.uploaded-image {
  div.step-details {
    text-align: center;
  }

  img.uploaded-image {
    height: 200px;
    border: 1px solid $themes__main_color;
  }

  div.image-details {
      margin: 10px 0;
  }
}
