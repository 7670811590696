
@import "colors.scss";

div.upload-progress
{
    margin: 10px 0;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 5px;        
    display: none;
    //display: block;

    > div
    {
        border-radius: 5px;        
        background-color: $themes__main_color;                
        height: 25px;
        width: 0%;
        //width: 50%;
    }
}
