@import "colors.scss";

div.upload-new-image {
  div.step-details {
    text-align: center;
  }

  div.file-selector {
    margin-bottom: 30px;

    input[type="file"] {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    label {
        font-size: 1.5rem;
        border: 2px solid $themes__main_color;
        padding: 5px 10px;
        color: $themes__main_color;
        border-radius: 3px;
        cursor: pointer;
        i
        {
            padding-right: 8px;
            cursor: pointer;
            font-size: 2rem;
        }
    }
  }
}

div.drag-drop-file {
  width: 500px;
  padding: 100px 0;
  background-color: #ececec;
  border: 5px solid $themes__main_color;
  border-style: dashed;
  //opacity: .5;
  border-radius: 10px;
  font-size: 2rem;
  color: #999;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media all and (max-width: 599px) {
  div.drag-drop-file {
    width: 100%;
    font-size: 5vw;
  }
}


@media all and (max-width: 399px) {
    div.upload-new-image {
        
        div.file-selector {
          margin-bottom: 20px;
      
          
          label {
              font-size: 1.5rem;              
              padding: 1px 10px;              
          }
        }
      }
  }
