@import "colors.scss";

div.success {
  div.step-details {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    width: 100%;

    > div.item {
      flex: 0 0 300px;      
      margin: 0 20px 20px 0;      
      //background-color: pink;
    }
  }

  div.download {
    text-align: center;
    margin-bottom: 10px;
    a {
      display: inline-block;
      font-size: 1rem;
      background: $themes__main_color;
      color: #fff;
      border-radius: 10px;
      padding: 8px 20px;
      padding-bottom: 8px;
    }
  }
  div.image {
    text-align: center;    
    width: 100%;
    //background-color: blue;
    height: 300px;
    object-fit: contain;
  }
  img.uploaded-image {
    //object-fit: contain;
    height: 300px;
    //max-width: 300px;    
  }
}
