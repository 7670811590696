@import "colors.scss";

body {
  padding-bottom: 20px;
  font-size: 16px !important;
  font-family: "Roboto", sans-serif;
}


body,
button {
  color: $body__font_color;
}
a {
  color: $themes__main_color;
}

/* Set padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

footer {
  text-align: center;
}

button,
input[type="button"],
input[type="file"] {
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #dcdcdc;
}

@media all and (max-width: 750px) {
}

@media all and (max-width: 991px) {
}
