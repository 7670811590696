@import "colors.scss";

div.resize-options {
  div.step-details {
    border: 1px solid $themes__main_color;
    padding: 10px;
    border-radius: 10px;

    div.resize-option {
      padding: 0 20px;
      border-right: 1px solid #dcdcdc;

      label {
        font-size: 1.3rem;
      }

      input[type="radio"] {
        margin: 0 10px 0 0;
      }

      div.description {
        font-size: 0.9rem;
      }
    }
    div.resize-option:last-child {
      border: 0;
    }
  }

  div.common-dimensions,
  div.multiple-dimensions {
    button {
      margin: 0 10px 10px 0;
    }
  }

  div.extact-dimensions {
    label {
      display: block;
    }
    input {
      display: inline-block;
      width: 40%;
      margin: 0 10px;
    }
  }
}
