@import "colors.scss";

div.processing {
    margin-bottom: 0 !important;
  div.step-details {
    text-align: center;
    width: 50%;
    padding: 100px;
    margin-left: auto;
    margin-right: auto;
    //background: $themes__main_color_light;
    //background: #000;
    border-radius: 10px;
    //opacity: .5;
    font-size: 2rem;
    color: #999;    
  }  
}


// div.uploading {
//     div.step-details {
      
//     }  
//   }


