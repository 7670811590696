@import "colors.scss";

header {
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 20px;
  background-color: $themes__main_color;
  border-bottom: 5px solid $themes__secondary_color;  
  text-shadow: 2px 2px 2px #333;

  a.site-name {    
    color: #fff;
  }
  h1 {
    font-size: 4rem;
    font-family: 'Bitter', serif;
  }
  h2 {
    font-size: 1.8rem;    
    color: #fff;
    font-family: 'Bitter', serif;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 1rem;    
    color: #fff;
    text-shadow: 2px 2px 2px #333;
    padding: 0 20px;
  }
}

@media all and (max-width: 499px) {
  header {
    h1 {
        font-size: 10vw;
    }
    h2 {
        font-size: 5vw;
    }
  }
}
